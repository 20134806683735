/* React Multi Carousel Custom css */
.heightFull,
.heightFull .carouselWrapper,
.heightFull .carouselWrapper>.swiper-container,
.heightFull .carouselWrapper>.swiper-container>.swiper-wrapper,
.heightFull .carouselWrapper>.swiper-container>.swiper-wrapper>.swiper-slide {
	height: 100%;
}

.swiper-wrapper .swiper-slide {
	padding-bottom: 0px;
	height: auto;
}

.swiper-container .swiper-button-prev,
.swiper-container .swiper-button-next {
	display: none;
}

.product-carousel .swiper-pagination-bullets .swiper-pagination-bullet {
	height: 6px !important;
	width: 20px !important;
	border-radius: 10px !important;
}

.product-carousel .swiper-pagination-bullets .swiper-pagination-bullet-active {
	width: 34px !important;
}

.carouselWrapper .swiper-container .swiper-pagination-bullets .swiper-pagination-bullet {
	width: 8px;
	height: 8px;
	border: 0;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
	background-color: #fff;
	border-radius: 8px;
	transition: all 0.3s ease-in-out;
	opacity: 1;
}

.carouselWrapper .swiper-container .swiper-pagination-bullets {
	bottom: 10px;
}

.carouselWrapper .swiper-container .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
	width: 16px;
	background-color: #000;
}


@media screen and (min-width: 480px) {
	.carouselWrapper .swiper-container .swiper-pagination-bullets {
		bottom: 20px;
	}

	.carouselWrapper .swiper-container .swiper-pagination-bullets .swiper-pagination-bullet {
		width: 12px;
		height: 12px;
	}

	.carouselWrapper .swiper-container .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
		width: 20px;
	}
}

@media screen and (min-width: 1025px) {
	.carouselWrapper .swiper-container .swiper-pagination-bullets {
		bottom: 28px;
	}

	.carouselWrapper.pagination-left .swiper-pagination-bullets {
		bottom: 52px;
	}

	.carouselWrapper .swiper-container .swiper-pagination-bullets .swiper-pagination-bullet {
		width: 16px;
		height: 16px;
		margin-left: 8px;
		margin-right: 8px;
	}

	.carouselWrapper .swiper-container .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
		width: 28px;
	}
}

.carouselWrapper.dotsCircle .swiper-container .swiper-pagination-bullets .swiper-pagination-bullet {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	margin-left: 4px;
	margin-right: 4px;
	opacity: 0.5;
	transform: scale(0.7);
}

.carouselWrapper.dotsCircle .swiper-container .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
	background-color: #fff;
	opacity: 1;
	transform: scale(1);
}

.testimonial-carousel .swiper-container {
	padding-bottom: 35px;
}

.swiper-scrollbar {
	display: none;
}

.testimonial-carousel .swiper-horizontal .swiper-scrollbar {
	background-color: #f1f1f1;
	display: block;
}

.testimonial-carousel .swiper-scrollbar-drag {
	background-color: #212121;
}

@media screen and (min-width: 768px) {
	.testimonial-carousel .swiper-slide-active>div {
		background-color: #fff;
		box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.08);
	}

	.testimonial-carousel .swiper-container .swiper-wrapper>div {
		padding: 15px 14px 0;
	}

	.testimonial-carousel .swiper-container {
		padding-bottom: 45px;
		margin-left: -30px;
		margin-right: -14px;
		margin-top: -15px;
	}

	.testimonial-carousel .swiper-container .swiper-wrapper>div.swiper-slide-active {
		padding-left: 30px;
	}

	.testimonial-carousel .swiper-container-horizontal .swiper-scrollbar {
		width: calc(100% - 44px);
	}
}

@media screen and (min-width: 1280px) {
	.testimonial-carousel .swiper-container {
		padding-bottom: 60px;
	}

	.teamCarousel .swiper-wrapper {
		justify-content: space-evenly;
	}
}

.hero-grid-carousel .carouselWrapper .swiper-container-horizontal>.swiper-scrollbar {
	left: 0;
	width: 100%;
	display: block;
}

.hero-grid-carousel .carouselWrapper .swiper-container-horizontal>.swiper-wrapper {
	padding-bottom: 60px;
}

.swiper-pagination-bullet-active {
	background: #6D1178 !important;
	width: 20px !important;
	border-radius: 5px !important;
}

.swiper-pagination {
	bottom: -7px !important;
}

.shadow-product-box {
	box-shadow: 0 0 #0000 !important;
}

.swiper-bottom-pagination .swiper-pagination-bullets.swiper-pagination-horizontal {
	bottom: 5px !important;
}

.swiper-bottom-pagination .swiper-backface-hidden .swiper-slide {
	padding-bottom: 14px;
}

.swiper-bottom-pagination .swiper-navigation {
	bottom: -35px;
	position: relative;
	border-radius: 5px;
}

.swiper-bottom-pagination .arrow-btns.right {
	right: 0;
	box-shadow: 0px 0px 8px 0px #00000020
}

.swiper-bottom-pagination .arrow-btns.left {
	right: 50px;
	left: auto;
	box-shadow: 0px 0px 8px 0px #00000020
}

.swiper-bottom-pagination .arrow-btns {
	display: flex;
	background: #ffffff;
	border-radius: 50px;
	height: 35px;
	width: 35px;
	align-items: center;
	justify-content: center;
}

.center-carousel .swiper-wrapper {
	display: flex;
	justify-content: center;
}

.three-row-swiper .swiper {
	width: 100%;
	height: 100%;
	margin-left: auto;
	margin-right: auto;
}

.three-row-swiper .swiper-slide {
	text-align: center;
	font-size: 18px;
	background: #fff;
	height: calc((100% - 30px) / 2) !important;

	/* Center slide text vertically */
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 0 !important;
}

.three-row-swiper.swiper-grid-column>.swiper-wrapper {
	flex-wrap: no-wrap;
	flex-direction: unset;
}
.index-carousel .swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 8.25rem !important;
    padding-left: 9rem;
}
.common-testimonial-list .swiper.swiper-initialized.swiper-horizontal{
	padding-bottom: 62px;
}

@media only screen and (min-width: 300px) and (max-width: 800px) {
	.common-testimonial-list .swiper.swiper-initialized.swiper-horizontal{
		padding-bottom: 32px;
	}
	
}

@media only screen and (min-width: 901px) and (max-width: 1119px) {
	.index-carousel .swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
		bottom: 0rem !important;
		padding-left: 0;
	  }
}

@media only screen and (min-width: 300px) and (max-width: 1000px) {
	.index-carousel .swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
	  bottom: 1rem !important;
	  padding-left: 0;
	}
	.swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
		bottom: -35px !important;
	}
	/* .swiper.swiper-initialized.swiper-horizontal{
		overflow: visible;
	} */
	
	}
	
@media screen and (min-width: 1300px) {
	.index-carousel .swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
		bottom: 2.5rem !important;
		padding-left: 9rem;
	}
}
@media screen and (min-width: 1900px) {
	.index-carousel .swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
		bottom: 0.3rem !important;
		padding-left: 9rem;
	}
}